<template>
  <div class="avatar">
    <p>
      <img class="avatar_img" :src="$store.state.userInfo.avatar"/>
    </p>
    <div>
      <p>用户名 : {{ $store.state.userInfo.name }}</p>
      <div class="invite_code" style>
        邀请码 :
        <div id="invite" @mouseover="enlargeImg()" @mouseout="hoverout"></div>
        <div class="large_img" v-show="isShow">
          <div id="avatar_qrcode"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getInviteApi} from "api/AdminCenter";
import QRcode from "qrcodejs2";

export default {
  data() {
    return {
      isShow: false,
      imgUrl: "" //邀请码
    };
  },
  methods: {
    //放大
    enlargeImg() {
      this.isShow = true;
    },
    //消失
    hoverout() {
      this.isShow = false;
    }
  },
  mounted() {
    this.$store.dispatch({
      type: "getUserInfo"
    });
    //获取邀请码
    getInviteApi().then(res => {
      if (res.code == 0) {
        const url = res.data.invite_code_url;
        this.imgUrl = url;
        new QRcode("invite", {
          text: url,
          width: 200, //图像宽度
          height: 200, //图像高度
          colorDark: "#673DCB",
          colorLight: "#ffffff",
          correctLevel: QRcode.CorrectLevel.H
        });
        new QRcode("avatar_qrcode", {
          text: this.imgUrl,
          width: 100, //图像宽度
          height: 100, //图像高度
          colorDark: "#673DCB",
          colorLight: "#ffffff",
          correctLevel: QRcode.CorrectLevel.H
        });
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.avatar {
  position: relative;
  padding: 30px 0;
  display: flex;
  align-items: center;
  width: 230px;
  justify-content: space-between;

  .invite_code {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .avatar_img {
    width: 44px;
  }

  #invite {
    display: inline-block;

    ::v-deep img {
      margin-left: 10px;
      width: 30px;
    }
  }

  .large_img {
    position: absolute;
    top: 0;
    left: 170px;
  }
}
</style>