<!-- *************************** 产品购买 ****************************** -->
<template>
  <div class="container membership">
    <div class="top">
      <Avatar />
      <div class="balance" v-if="productLists.length>0">
        <div>
          <p class="balance_title">钱包余额:</p>
          <p class="balance_price">{{user_credit/100}} {{productLists[mealsIndex].currency}}</p>
        </div>
        <div>
          <p class="balance_title">推广奖励金额:</p>
          <p class="balance_price">{{reward_credit/100}} {{productLists[mealsIndex].currency}}</p>
        </div>
      </div>
    </div>
    <div class="meals_box" v-if="productLists.length>0">
      <div
        v-for="(item,index) in productLists"
        :key="item.order_id"
        @click="choseMeals(index)"
        :class="[{'meals_active':mealsIndex==index},'meals']"
        :style="{marginRight:(index+1)%3==0?0:'338px'}"
      >
        <p class="price">
          {{item.price/100}}
          <span class="currency">{{item.currency}}</span>
        </p>
        <p class="sale_price">
          售价
          <span class="inner_price">{{item.price/100}}{{item.currency}}</span>
        </p>
      </div>
    </div>
    <!--*******  支付方式  ******** -->
    <div>
      <div class="pay_way">
        <em></em>选择支付方式
      </div>
      <div class="pay">
        <div class="pay_type">
          <Button
            :class="[{'pay_active':payIndex==index},'item']"
            v-for="(item,index) in payWays"
            :key="item.key"
            @click="payChose(index)"
          >
            <div class="img">
              <img v-if="index==0" src="../../assets/royalpay.png" alt />
              <img v-if="index==1" src="../../assets/paypal2.png" height="30px" width="120px" alt />
            </div>
            <span>{{item.title}}</span>
          </Button>
        </div>
        <div>
          <el-radio-group v-model="channel" v-if="payIndex<1">
            <el-radio label="Wechat" border>微信支付</el-radio>
            <el-radio label="Alipay" border>支付宝支付</el-radio>
          </el-radio-group>
        </div>
        <div class="rate" v-if="channel=='Wechat'&&payIndex<1">
          <span class="rate_title">微信汇率</span>
          <span class="detail_price">{{wechat_rate}}</span>
        </div>
        <div class="rate" v-if="channel=='Alipay'&&payIndex<1">
          <span class="rate_title">支付宝汇率</span>
          <span class="detail_price">{{alipay_rate}}</span>
        </div>
        <div class="info" v-if="productLists.length>0">
          <span>应付总价</span>
          <span
            class="detail_price"
          >{{productLists[mealsIndex].price/100}}{{productLists[mealsIndex].currency}}</span>
        </div>
        <div class="bug_now">
          <button @click="submit">立即购买</button>
        </div>
        <!--*****************************  二维码弹框   **********************************-->
        <div class="mask" v-if="isShow">
          <div class="qr_box">
            <div class="qr_inner_box">
              <h1>{{channel=='Wechat'?'微信支付':'支付宝支付'}}</h1>
              <div id="memberRechargeQrcode"></div>
              <div class="close">
                <i
                  @click="closeMask"
                  style="color: #33333378;font-size: 20px;"
                  class="el-icon-close"
                ></i>
              </div>
            </div>
          </div>
          <!--*******  付款成功后提示弹框   ******** -->
          <div class="qr_box" v-if="status">
            <div class="inner_box">
              <i
                style="font-size:50px"
                :class="{'el-icon-circle-check':status==1,'el-icon-circle-close':status==2||status==3}"
              ></i>
              <p style="font-size:20px;margin-top:20px;">{{statusStr[status]}}</p>
              <div class="btn_bottom" v-if="status==1">
                <router-link to="/admin_center/myorder">
                  <button>查看订单</button>
                </router-link>
                <button @click="closeMask" style="margin-left: 80px;">继续逛逛</button>
              </div>
              <div class="close">
                <i
                  @click="closeMask"
                  style="color: #33333378;font-size: 20px;"
                  class="el-icon-close"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <!--*****************************  二维码弹框   **********************************-->
      </div>
    </div>
  </div>
</template>
<script>
import {
  getProductListApi,
  getProductRateApi,
  submitOrderApi,
  getOrderStatusApi,
  getWalletInfoApi
} from "api/Recharge";
import QRcode from "qrcodejs2";
import Avatar from "./Avatar";
export default {
  components: {
    Avatar
  },
  data() {
    return {
      productLists: [],
      mealsIndex: 0, //当前选择的套餐序列
      channel: "Wechat", //royalpay默认选择微信支付
      payWays: [
        { key: "RoyalPay", title: "" },
        // { key: "Paylinx", title: "" },
        { key: "Paypal", title: "" },
      ],
      payIndex: 0, //当前选择的支付序列  0：royalpay,1:钱包
      rate:{
        RoyalPay:{
          alipay_rate: "", //支付宝汇率
          wechat_rate: "", //微信汇率
        },
        Paylinx:{
          alipay_rate: "", //支付宝汇率
          wechat_rate: "", //微信汇率
        }
      },
      alipay_rate: "", //支付宝汇率
      wechat_rate: "", //微信汇率
      isShow: false, //支付二维码弹窗
      status: "", //订单状态
      statusStr: {
        0: "待支付",
        1: "支付成功",
        2: "支付失败",
        3: "订单超时未支付"
      },
      timer: null,
      user_credit: "",
      reward_credit: ""
    };
  },
  methods: {
    //点击选择套餐
    choseMeals(index) {
      this.mealsIndex = index;
    },
    payChose(index) {
      this.payIndex = index;
      this.changeRate(index)
    },
    changeRate(index){
      if (this.payWays[index].key == 'RoyalPay') {
        this.wechat_rate = this.rate.RoyalPay.wechat_rate
        this.alipay_rate = this.rate.RoyalPay.alipay_rate
      }
       if (this.payWays[index].key == 'Paylinx') {
        this.wechat_rate = this.rate.Paylinx.wechat_rate
        this.alipay_rate = this.rate.Paylinx.alipay_rate
      }
    },
    //提交订单
    submit() {
      const loading = this.$loading({
        lock: true,
        text: "提交订单中。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const params = {
        payment_type: this.payWays[this.payIndex].key,
        product_id: this.productLists[this.mealsIndex].id,
        channel: this.channel
      };
      submitOrderApi(params)
        .then(res => {
          if (res.code == 0) {
            if (params.payment_type == 'Paypal') {
                window.open(res.data.code_url, '_blank');
            } else {
            this.isShow = true;
            setTimeout(() => {
              let shareUrl = res.data.code_url;
              let qrcode = new QRcode("memberRechargeQrcode", {
                text: shareUrl,
                width: 200, //图像宽度
                height: 200, //图像高度
                colorDark: "#517cfc",
                colorLight: "#ffffff",
                correctLevel: QRcode.CorrectLevel.H
              });
              qrcode.clear(); //清除二维码
              qrcode.makeCode(shareUrl); //生成另一个新的二维码
              this.timer = setInterval(
                () => this.getResult(res.data.order_id),
                1000
              );
            }, 500);
            }
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    //查看支付订单状态
    getResult(order_id) {
      if (this.isShow) {
        getOrderStatusApi({ order_id }).then(res => {
          if (res.code == 0) {
            const { status } = res.data;
            //订单状态:0待支付/1支付成功/2支付失败/3订单关闭
            if (status != 0) {
              this.status = status;
              clearInterval(this.timer);
            }
          } else {
            clearInterval(this.timer);
            this.isShow = false;
          }
        });
      }
    },
    //关闭弹窗
    closeMask() {
      this.isShow = false;
      this.status = 0
      clearInterval(this.timer);
    }
  },
  mounted() {
    //获取套餐列表
    getProductListApi({type:5}).then(res => {
      if (res.code == 0) {
        this.productLists = res.data;
      }
    });
    //获取汇率
    getProductRateApi({payment_type:"RoyalPay"}).then(res => {
      if (res.code == 0) {
        if (res.data.RoyalPay != undefined) {
          this.rate.RoyalPay.wechat_rate = res.data.RoyalPay.wechat_rate
          this.rate.RoyalPay.alipay_rate = res.data.RoyalPay.alipay_rate
        }

        if (res.data.Paylinx != undefined) {
          this.rate.Paylinx.wechat_rate = res.data.Paylinx.wechat_rate
          this.rate.Paylinx.alipay_rate = res.data.Paylinx.alipay_rate
        }

      this.changeRate(0)
      }
    });
    //获取钱包信息
    getWalletInfoApi().then(res => {
      if (res.code == 0) {
        const { user_credit, reward_credit } = res.data;
        this.user_credit = user_credit;
        this.reward_credit = reward_credit;
      }
    });
  }
};
</script>
<style lang='scss'>
.membership {
  .top {
    @include flex_between;
  }
  .balance {
    margin: 30px 0;
    width: 341px;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px 2px 9px 0px rgba(181, 193, 203, 0.3);
    border-radius: 4px;
    @include flex_around;
    font-size: 14px;
    font-weight: 400;
    align-items: center;
    text-align: left;
    .balance_title {
      color: #091d40;
      padding-bottom: 10px;
    }
    .balance_price {
      color: #517cfc;
    }
  }
  .meals_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .meals_active {
      border: 2px solid #517cfc;
    }
    .meals {
      cursor: pointer;
      width: 168px;
      height: 118px;
      background: #edf9ff;
      border-radius: 4px;
      text-align: center;
      box-sizing: border-box;
      padding: 19px;
      margin-bottom: 24px;
      .price {
        font-size: 28px;
        font-weight: 600;
        color: #517cfc;
        margin-bottom: 20px;
        .currency {
          font-size: 18px;
        }
      }
      .sale_price {
        font-size: 14px;
        font-weight: 600;
        color: #bcc5d4;
      }
    }
  }
  .pay_way {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 22px;
    font-weight: 400;
    color: #1a1a1a;
    line-height: 30px;
    margin-bottom: 30px;
    vertical-align: middle;
    em {
      display: inline-block;
      width: 4px;
      height: 10px;
      background: #517cfc;
      margin-right: 10px;
    }
  }
  .pay {
    text-align: left;
    padding: 16px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 100px;
    .pay_type {
      display: flex;
      .item:first-child {
        img {
          width: 120px;
        }
      }
      .item {
        margin-right: 20px;
        height: 55px;
        width: 140px;
        padding: 10px;
        cursor: pointer;
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        background: #fff;
        border: 1px solid #dcdfe6;
        &:hover {
          span {
            color: #517cfc;
          }
        }
        span {
          margin-left: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #4c5a68;
          line-height: 20px;
        }
        .paypal {
          width: 30px;
        }
      }
      .pay_active {
        background: #e6a23c29;
        color: #517cfc;
      }
    }
    .el-radio.is-bordered {
      display: block;
      margin-bottom: 15px;
    }
    .el-radio.is-bordered + .el-radio.is-bordered {
      margin-left: 0;
    }
    ul {
      li {
        color: #7e7e7e;
        font-size: 14px;
        margin-bottom: 10px;
        .li_item {
          @include flex_between;
          p {
            .day {
              color: #000;
            }
            .account {
              color: #6dd400;
            }
          }
        }
      }
    }
    .all_pay {
      font-size: 18px;
      color: #202020;
      margin-top: 20px;
      @include flex_between;
    }
    .bug_now {
      text-align: left;
      margin-top: 20px;
      button {
        width: 128px;
        height: 40px;
        background: #517cfc;
        border-radius: 4px;
        color: #fff;
      }
    }
    .rate {
      @include flex_between;
      font-size: 18px;
      font-weight: 400;
      color: #202020;
    }
    .detail_price {
      color: #ff6854;
    }
    .info {
      font-size: 18px;
      font-weight: 400;
      margin: 15px 0;
      color: #202020;
      @include flex_between;
    }
  }
  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    .qr_box {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      width: 430px;
      height: 430px;
      border-radius: 5px;
      .qr_inner_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          font-weight: 600;
          font-size: 28px;
          margin: 47px;
        }
      }
      .inner_box {
        position: relative;
        padding: 120px 0;
        .el-icon-circle-check {
          color: green;
        }
        .btn_bottom {
          margin-top: 80px;
          button {
            width: 120px;
            height: 40px;
            line-height: 40px;
            color: #fff;
            background: #517cfc;
            font-size: 16px;
            text-align: center;
          }
        }
      }
      .close {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}
</style>